<template>
  <div class="nocsWrapper">
    <tableHeader
      :result="{ pageTitle: pageTitle  }"
      :currentTable="dummyTable"
      :quickFilter="true"
      :tableDataSet="result"
      @quick-filter-needle="getNeedle(...arguments)"
    ></tableHeader>
    <v-card class="mt-1 mb-5">
      <v-expansion-panels v-model="openAccordions" focusable multiple>
        <v-expansion-panel>
          <v-expansion-panel-header color="pageTitleHeader ocsAccordion">
            <div
              class="h4 font-weight-bold ml-5"
              v-if="result.realSubType == 'selenium'"
            >
              Parameters
            </div>
            <div class="h4 font-weight-bold ml-5" v-else>
              {{ languagePack("ocs", "title_connection") }}
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row no-gutters v-if="!empty(result.ocsTypes)">
              <v-col
                v-for="(ocsTableInfo, catKey) in result.connectionInfo"
                :key="catKey"
              >
                <ocsOuterTable
                  :ocsTableInfo="ocsTableInfo"
                  :result="result"
                  :search="search"
                  :filter="filter"
                ></ocsOuterTable>
              </v-col>
            </v-row>
            <div v-else>{{ languagePack("info_notActiveType") }}</div>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel
          v-if="typeof result.ocsTemplateTableArray != 'undefined'"
        >
          <v-expansion-panel-header
            color="pageTitleHeader ocsAccordion"
            id="templateAccordionHeader"
            @keyup.space.prevent
          >
            <v-col cols="9">
              <v-row no-gutters>
                <div class="h4 font-weight-bold ml-2">
                  {{ languagePack("ocs", "title_template") }}
                </div>
                <templateMenu :result="result"></templateMenu>
              </v-row>
            </v-col>
            <v-col cols="3" style="max-width: 335px">
              <searchInput
                v-model="templateSearch"
                @focus="checkFocused"
              ></searchInput>
            </v-col>
          </v-expansion-panel-header>
          <v-expansion-panel-content id="templateConfigAccordion">
            <div class="" style="overflow: visible">
              <div class="">
                <v-row no-gutters class="" style="font-weight: bold">
                  <div style="width:49%;margin-left: 105px;">
                    {{ languagePack("ocs", "title_templ_name") }}
                  </div>
                  <div style="">
                    {{ languagePack("ocs", "title_templ_description") }}
                  </div>
                </v-row>
                <div v-if="!empty(result.ocsTemplateTableArray)">
                  <ocsMainTable
                    :ocsTableInfo="result.ocsTemplateTableArray"
                    :result="result"
                    :filter="filter"
                    :search="templateSearch"
                  >
                  </ocsMainTable>
                </div>

                <div v-else>{{ languagePack("info_NoRecords") }}</div>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </div>
</template>
<script>
import templateMenu from "@/components/legacy/templateMenu.vue";
import ocsOuterTable from "@/components/legacy/ocsOuterTable.vue";
import ocsMainTable from "@/components/legacy/ocsMainTable.vue";
import tableHeader from "@/commonComponents/tableHeader.vue";
import searchInput from "@/commonComponents/searchInput.vue";
import requests from '@/js/requests';
export default {
  data: function () {
    return {
      templateSearch: "",
      dummyTable: { searchValue: "" },
      filter: "all",
      openAccordions: [0, 1], //panel open
    };
  },
  components: {
    ocsOuterTable,
    ocsMainTable,
    tableHeader,
    searchInput,
    templateMenu,
  },
  computed: {
    cType() {
      return this.result.quickSearchAttr;
    },
    search() {
      return this.dummyTable.searchValue;
    },
    pageTitle(){
        let title = this.getMappedName(this.result.realSubType);
        if(this.$route.query.p=="5G"){
           title += " "+this.languagePack("5gTitle", "nFunction");
        }else if(this.$route.query.p=="2G/3G") {
           title = this.languagePack("2gTitle", "radius");
        }else if(this.$route.query.c_subType=="BRAS" && this.$route.query.p=="Fixed Net") {
           title = this.languagePack("fixedNetTitle", "bras");
        }

        if(this.$route.query.f2=="inboundConnections"){
            title+=" "+this.languagePack("table_inbound");
        }else{
            title+=" "+this.languagePack("table_outbound");
        }
          return title;
      },
  },
  props: ["result"],
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (
        mutation.type === "dataSubmitted"
        // &&
        //  typeof state.submittedData[this.result.dialogRef] != "undefined"
      ) {
        let latestRef = state.submittedData.latestRef;
        let functionName = state.submittedData[latestRef].data.functionName;
        let jsonFormData = state.submittedData[latestRef].data.jsonFormData;

        if (functionName == "edit") {
         // console.log(jsonFormData.c_type);
          let path;
          if (this.$route.query.f2 == "inboundConnections") {
            path =
              "serve.php?f=configuration&f2=inboundConnections&f3=inboundConnectionConfiguration";
          } else {
            path =
              "serve.php?f=configuration&f2=outboundConnections&f3=nocsConnection";
          }
          path +=
            "&p=" +
            this.$route.query.p +
            "&c_subType=" +
            this.$route.query.c_subType +
            "&c_type=" +
            jsonFormData.c_type;

          let caller = this;

          requests.frameworkAxiosRequest({
            method: 'post',
            url: path,
            data: {},
            //headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" }
          })
              .then(function (response) {
              // console.log(response);
              // caller.$router.push(response.data.result.redirect).catch(() => {
              //   caller.$router.go();
              // });
              caller.$store.commit("tableChanged", response.data.result.table);
            })
            .catch(function (response) {
              //handle error
              console.log(response);
            });
        }
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  methods: {
    checkFocused() {
      if (!this.openAccordions.includes(1)) {
        this.openAccordions.push(1);
      }
    },
    getNeedle: function (val) {
      this.filter = val;
    },
  },
};
</script>
<style scoped>
.ocsAccordion {
  min-height: 60px;
}
</style>